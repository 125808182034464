import React, { useState, useEffect, useContext } from 'react';
import { Box, Menu, ListItem, Typography, Avatar, Button } from '@mui/material';
import classes from './NewsCardGrid.module.css';
import inactiveThumbUpIcon from '../../assets/icons/inactive-thumb-up.svg';
import inactiveThumbUpIconDark from '../../assets/icons/inactive-thumb-up-white.svg';
import activeThumbUpIcon from '../../assets/icons/active-thumb-up.svg';
// import inactiveThumbDownIcon from '../../assets/icons/inactive-thumb-down.svg';
// import inactiveThumbDownIconDark from '../../assets/icons/inactive-thumb-down-white.svg';
// import activeThumbDownIcon from '../../assets/icons/activeHeartDislikeIconDarkMode.svg';
import commentIcon from '../../assets/icons/comment.svg';
import commentIconDark from '../../assets/icons/comment-white.svg';
import activeBookmarkIcon from '../../assets/icons/active-bookmark.svg';
import inactiveBookmarkIcon from '../../assets/icons/inactive-bookmark.svg';
import inactiveBookmarkIconDark from '../../assets/icons/Inactive-Bookmark-Dark.svg';
import disabledBookmarkIcon from '../../assets/icons/disabled-bookmark.svg';
import moreOptionsIcon from '../../assets/icons/vertical-ellipsis.svg';
import moreOptionsIconDarkMode from '../../assets/icons/EllipsisDarkMode.svg';
import labelWhiteIcon from '../../assets/icons/label-white.svg';
import labelWhiteIconDark from '../../assets/icons/StackDark.svg';
import locationIcon from '../../assets/icons/Location.svg';
import locationIconDark from '../../assets/icons/location-dark.svg';
import inactiveFireIcon from '../../assets/icons/inactive-fire.svg';
import inactiveFireIconDark from '../../assets/icons/FireDark.svg';
import activeHeartIcon from '../../assets/icons/active-heart.svg';
import inactiveHeartIcon from '../../assets/icons/inactive-heart.svg';
import inactiveHeartIconDark from '../../assets/icons/inactive-heart-dark.svg';
import flagIcon from '../../assets/icons/flag.svg';
import flagIconDark from '../../assets/icons/Flag-dark.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import noImage from '../../assets/icons/no-image.svg';
import noImageDark from '../../assets/icons/no-image-dark.svg';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import add from 'date-fns/add';
import ReportModal from '../Report/ReportModal';
import { useTranslation } from 'react-i18next';
import ShareDialog from '../share-modal/ShareModal';
import shareIcon from '../../assets/icons/share.svg';
import shareIconDark from '../../assets/icons/share-white.svg';
import {
  LIKE,
  DISLIKE,
  NONE,
  AUTHOR_NEWS,
  CITIZEN_FEED,
  AGENCY,
  // TRAINING_LANGUAGE,
  HEADLINE_FALLBACK,
  MORE_LABEL,
  ONE,
  TIME_UNITS,
  NO_LOCATION,
  FIVE,
  THIRTY,
  UGC_REPORTER,
  PUBLISHER,
  INFLUENCER,
  LIKE_NONE,
  DISLIKE_NONE,
  CITIZEN_REPORTER_CAPITAL,
  PUBLISHER_TYPE,
} from '../../Constants/Constants';
import './NewsCard.css';
import { UserDetailsProvider } from '../../store/user-details-context';
import JWPlayer from '@jwplayer/jwplayer-react';
import playIcon from '../../assets/icons/play.svg';
import newsService from '../../services/news.service';
import bookmarkService from '../../services/bookmark.service';
import { useMetaStore } from '../../store/MetaTagHandler';
import { metaAction } from '../../store/MetaTagHandler/metaAction';
import reportersLogo from '../../assets/defaultProfilePic.png';
import userProfileService from '../../services/user-profile.service';
import bluetickIcon from '../../assets/icons/blue-influencer-tick-icon.svg';
import purpletickIcon from '../../assets/icons/purple-tick.svg';
import { AppContext } from '../../store/app-context';
import InfoModal from '../Modal/InfoModal';
import anonymousIcon from '../../assets/icons/anonymous.svg';
import { EnvironmentProvider } from '../../store/firebase-context';
import { getReactionStatus } from '../../utils/news-card.util';
import parse from 'html-react-parser';

/**
 * NewsCard Grid Component
 */
export default function NewsCardGrid(props) {
  const {
    url,
    maincategoryIcon,
    mainCategory,
    mainCategoryUrl,
    headline,
    media,
    city,
    newsDate,
    likeCount,
    dislikeCount,
    authorName,
    authorID,
    newsID,
    authorImage,
    isLiked,
    isDisliked,
    isBookmark,
    isTrending,
    newsType,
    commentsCount,
    trackingID,
    locationID,
    hasVideo,
    verifiedByDT,
    onRemoveBookmark,
    onCardAction,
    anonymous,
    can_flag,
    user_type,
  } = props;

  const { dispatch: appCtxDispatch } = useContext(AppContext);
  //share popup state
  const [sharePopupOpen, setSharePopup] = useState(false);
  /**
   * we can openand close popup via shareHandle
   */
  const handleSharePopup = () => {
    setSharePopup(!sharePopupOpen);
  };
  // const location = useLocation();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatchMetaAction = useMetaStore()[1];
  const { environment } = useContext(EnvironmentProvider);
  const { state: userState } = useContext(UserDetailsProvider);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const isAuthenticated = userState?.isAuthenticated;
  const follows = userState?.follows;
  const [isReactionClicked, setIsReactionClicked] = useState(false);
  const [reaction, setReaction] = useState('');
  const [bookmarkState, setBookmarkState] = useState(false);
  const [removeBookmark, setRemoveBookmark] = useState(false);
  const [isBookmarkClicked, setIsBookmarkClicked] = useState(false);
  const [thumbState, setThumbState] = useState({
    likeCount,
    dislikeCount,
    isLiked,
    isDisliked,
  });

  const [openNewsCardpopover, setOpenNewsCardpopover] = useState(null);
  const [isFollowedByUser, setIsFollowedByUser] = useState(false);
  const [reportersImage, setReportersImage] = useState(null);
  const [isReporterImageLoading, setIsReporterImageLoading] = useState(true);
  const [isPrivatePost, setIsPrivatePost] = useState(false);

  const imageCount = media ? media.length - 1 : 0;
  const newsSessionID = userState?.sessionId;
  const history = useLocation();
  const [showReportModal, setShowReportModal] = useState(false);
  // const [showCommunityPopUp, setShowCommunityPopUp] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const userID = userState?.userDetails?.userId;
  const mediaObj = media?.length ? media[0] : null;

  /**
   * Checking the news type from news V2 api response and setting up the user type as publisher or reporter
   */
  let userType;
  if (newsType === AGENCY) {
    userType = PUBLISHER;
  } else if (newsType === INFLUENCER) {
    userType = INFLUENCER;
  } else if (newsType === CITIZEN_FEED) {
    userType = UGC_REPORTER;
  }

  const handleThumbUp = (event) => {
    event.stopPropagation();
    setIsReactionClicked(true);
    thumbState.isLiked || isLiked ? setReaction(LIKE_NONE) : setReaction(LIKE);
  };

  const handleThumbDown = (event) => {
    event.stopPropagation();
    setIsReactionClicked(true);
    thumbState.isDisliked || isDisliked
      ? setReaction(DISLIKE_NONE)
      : setReaction(DISLIKE);
  };

  const handleComments = (event, commentsCount, media) => {
    event.stopPropagation();
  };

  const handleMoreOptions = (event) => {
    event.stopPropagation();
    setOpenNewsCardpopover(event.currentTarget);
  };

  const handleBookmark = () => {
    setIsBookmarkClicked(true);
    bookmarkState || isBookmark
      ? setRemoveBookmark(true)
      : setRemoveBookmark(false);
  };

  const handleDistrict = (event) => {
    event.stopPropagation();
    navigate(`/districts/${city}-${locationID}`);
  };

  const handleCategory = (event) => {
    event.stopPropagation();
    navigate(mainCategoryUrl);
  };

  /**
   * to open or close share popup
   * @param {event}  onclick event
   */
  const handleShare = (event) => {
    handleSharePopup();
    event.stopPropagation();
  };

  /**
   * Handles the follow/unfollow action for a user (author, influencer, or publisher).
   *
   * @async
   * @function
   * @name handleFollowUnfollowAuthor
   * @throws {Error} If an error occurs during the follow/unfollow operation.
   * @returns {Promise<void>} A promise that resolves after the follow/unfollow operation is completed.
   *
   * @description
   * This function constructs followData object with user information and sends a follow or unfollow request to the userProfileService.
   *
   */
  const handleFollowUnfollowAuthor = async () => {
    try {
      const followData = {
        userId: userID,
      };
      if (isFollowedByUser) {
        followData['unfollow'] = JSON.stringify({ IDs: [trackingID] });
      } else {
        followData['follow'] = JSON.stringify({ IDs: [trackingID] });
      }
      await userProfileService.updateUserFollow(followData);
    } catch (error) {}
  };

  useEffect(() => {
    setThumbState((prev) => ({
      ...prev,
      likeCount,
      dislikeCount,
      isLiked: isLiked,
      isDisliked: isDisliked,
    }));
  }, [isLiked, isDisliked, likeCount, dislikeCount]);

  useEffect(() => {
    if (isReactionClicked) {
      updateUserNews();
    }
  }, [isReactionClicked, newsID, reaction]);

  useEffect(() => {
    setBookmarkState(isBookmark);
  }, [isBookmark]);

  useEffect(() => {
    if (isBookmarkClicked) {
      bookmarkNews();
    }
  }, [isBookmarkClicked, newsID, removeBookmark]);

  /**
   * calling the updateFollowButton if its not the first load of the fetchPreferences API and if there is change in author state, authorID and trackingID
   */
  useEffect(() => {
    updateFollowButton();
  }, [authorID, trackingID]);

  /**
   * Updates the state of the follow button based on whether the user is already following the author.
   *
   * @function
   * @name updateFollowButton
   * @returns {void}
   *
   * @description
   * This function checks if the author (identified by the trackingID) is present in the follows array,
   * and accordingly updates the state variable isFollowedByUser to control the state of the follow button.
   *
   */
  const updateFollowButton = () => {
    const isExistingAuthor = follows?.some((author) => author === trackingID);

    isExistingAuthor ? setIsFollowedByUser(true) : setIsFollowedByUser(false);
  };

  /**
   * SideEffect to run update the follow/unfollow button based upon the updated data by graphQL subscription
   */
  useEffect(() => {
    follows?.length && updateFollowButton();
  }, [follows]);

  /**
   * Side effect to calls Image API if reporter tracking ID is available
   */
  useEffect(() => {
    trackingID && getReportersImage();
  }, [trackingID]);

  const updateUserNews = async () => {
    try {
      const response = await newsService.updateUserNews({
        sessionId: newsSessionID,
        newsId: newsID,
        type:
          reaction === LIKE_NONE || reaction === DISLIKE_NONE ? NONE : reaction,
      });
      if (response?.errorMessage) {
        setIsPrivatePost(true);
      }
      const { isLiked, likeCount, isDisliked, dislikeCount } =
        getReactionStatus({ ...thumbState }, reaction);

      setThumbState({
        likeCount: likeCount,
        isLiked: isLiked,
        dislikeCount: dislikeCount,
        isDisliked: isDisliked,
      });

      onCardAction &&
        onCardAction({
          newsId: newsID,
          news_like_count: likeCount,
          is_liked: isLiked,
          news_dislike_count: dislikeCount,
          is_disliked: isDisliked,
        });

      setIsReactionClicked(false);
    } catch (error) {}
  };

  const bookmarkNews = async () => {
    try {
      const response = await bookmarkService.removeBookmarks({
        sessionId: newsSessionID,
        newsId: newsID,
        remove: removeBookmark,
      });
      if (response) {
        setBookmarkState(!removeBookmark);
        onCardAction &&
          onCardAction({
            newsId: newsID,
            is_bookmark: !removeBookmark,
          });
        setIsBookmarkClicked(false);
        removeBookmark && onRemoveBookmark && onRemoveBookmark(newsID);
      }
    } catch (error) {}
  };

  const handleNewsDetail = (headLine, url) => {
    dispatchMetaAction(metaAction.updateTitle, headLine);
    navigate(`/news${url}`);
  };

  const handleAuthor = (event) => {
    event.stopPropagation();
    if (userType === INFLUENCER && !trackingID) {
    } else {
      dispatchMetaAction(metaAction.updateTitle, `${authorName}`);
      navigate(`/${AUTHOR_NEWS}/${authorName}`);
    }
  };

  const handleSignInModal = (event) => {
    event.stopPropagation();
    appCtxDispatch({ type: 'SHOW_LOGIN_REQUIRED' });
  };

  const timeInIST = add(new Date(newsDate), {
    hours: FIVE,
    minutes: THIRTY,
  });

  const timeInHours = formatDistanceToNowStrict(timeInIST)
    .replace(TIME_UNITS, '')
    .split(' ')
    .join('');

  /**
   * close Report Modal
   */
  const closeModal = () => {
    setShowReportModal(false);
  };

  // const handlePostCreation = (media) => {};

  /**
   * News Card Focus Handler
   */
  const onCardFocus = () => {
    hasVideo && mediaObj?.jwp_supported && setShowVideo(true);
  };

  /**
   * News Card Blur Handler
   */
  const onCardBlur = () => {
    hasVideo && mediaObj?.jwp_supported && setShowVideo(false);
  };

  /**
   * Function to retrieve images using the userId of the the reporters
   */
  const getReportersImage = () => {
    userProfileService.getProfileImage(trackingID).then((image) => {
      setReportersImage(image);
      setIsReporterImageLoading(false);
    });
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: isDarkMode
            ? (theme) => theme.palette.grey[200]
            : (theme) => theme.palette.white[100],
          border: '1px solid',
          borderColor: (theme) => theme.palette.grey[200],
        }}
        className={classes.newsCard}
        onClick={() => handleNewsDetail(headline, url)}
        onMouseEnter={onCardFocus}
        onMouseLeave={onCardBlur}
      >
        <Box className={classes.newsCardHeaderBox}>
          <div
            className={classes.newsCardHeaderContainer}
            onClick={(e) => {
              if (!anonymous) {
                handleAuthor(e);
              }
            }}
          >
            {anonymous ? (
              <img
                src={anonymousIcon}
                className={
                  isDarkMode
                    ? classes.authorAnonymousCircleDark
                    : classes.authorAnonymousCircle
                }
                alt='anonymous-post'
              />
            ) : (
              <>
                {userType === PUBLISHER &&
                  (authorImage ? (
                    <img
                      src={authorImage}
                      className={classes.authorImgSquare}
                      alt='author-img'
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = reportersLogo;
                      }}
                    />
                  ) : (
                    <div className={classes.newsCardSquare}></div>
                  ))}

                {(userType === UGC_REPORTER || userType === INFLUENCER) &&
                  (reportersImage || authorImage ? (
                    (authorImage ? authorImage : !isReporterImageLoading) && (
                      <>
                        <Avatar
                          src={reportersImage ? reportersImage : authorImage}
                          className={
                            verifiedByDT === true
                              ? classes.authorDtVerifiedCircle
                              : classes.authorImgCircle
                          }
                          alt='author-img'
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = reportersLogo;
                          }}
                          sx={{
                            cursor: 'pointer',
                            position: 'relative',
                            width: '2.25rem',
                            height: '2.25rem',
                            borderRadius: '50%',
                            color: (theme) => theme.palette.white[100],
                            fontSize: '1rem',
                            fontWeight: '600',
                            backgroundColor: authorName
                              ? (theme) => theme.palette.avatar[100]
                              : 'unset',
                          }}
                        >
                          {authorName ? (
                            `${(
                              authorName?.charAt(0) || ''
                            ).toUpperCase()}${(authorName?.indexOf(' ') !== -1
                              ? authorName?.charAt(authorName.indexOf(' ') + 1)
                              : ''
                            )?.toUpperCase()}`
                          ) : (
                            <img src={reportersLogo} alt='reporters-logo' />
                          )}
                        </Avatar>
                      </>
                    )
                  ) : (
                    <div
                      className={
                        verifiedByDT === true
                          ? classes.authorDtVerified
                          : classes.newsCardCircle
                      }
                    ></div>
                  ))}
              </>
            )}
            <div className={classes.newsCardTitle}>
              <Box
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                <Typography variant='subtitle3'>
                  {anonymous ? t('anonymous') : authorName ? authorName : ''}
                  {user_type === PUBLISHER_TYPE && (
                    <Box
                      component='img'
                      src={bluetickIcon}
                      alt='green-tick'
                      width={15}
                      sx={{ height: 'auto', marginLeft: '2px' }}
                    ></Box>
                  )}
                  {user_type === INFLUENCER && (
                    <Box
                      component='img'
                      src={bluetickIcon}
                      alt='blue-tick'
                      width={15}
                      sx={{ height: 'auto', marginRight: '6px' }}
                    ></Box>
                  )}
                  {user_type === UGC_REPORTER && (
                    <Box
                      component='img'
                      src={bluetickIcon}
                      alt='reporter'
                      width={15}
                      sx={{ height: 'auto', marginLeft: '2px' }}
                    ></Box>
                  )}
                  {user_type === CITIZEN_REPORTER_CAPITAL && (
                    <Box
                      component='img'
                      src={purpletickIcon}
                      alt='reporter'
                      width={15}
                      sx={{ height: 'auto', marginLeft: '2px' }}
                    ></Box>
                  )}
                </Typography>
              </Box>
              <Typography
                variant='subtitle4'
                sx={{ fontSize: '0.75rem' }}
                className={classes.hours}
              >
                {timeInHours}
              </Typography>
            </div>
          </div>
          <div className={classes.headerActionsContainer}>
            {isTrending && (
              <img
                src={isDarkMode ? inactiveFireIconDark : inactiveFireIcon}
                alt='active-fire-icon'
                className={classes.icons}
                onClick={(event) => event.stopPropagation()}
              />
            )}

            {bookmarkState && (
              <img
                src={disabledBookmarkIcon}
                alt='disabled-bookmark-icon'
                onClick={(event) => event.stopPropagation()}
              />
            )}

            <img
              src={isDarkMode ? moreOptionsIconDarkMode : moreOptionsIcon}
              alt='more-options-icon'
              onClick={(event) => {
                handleMoreOptions(event);
              }}
            />
          </div>
        </Box>

        <div
          className={`news-card-player ${classes.newsCardMedia}`}
          onClickCapture={(e) => {
            if (!e.target.classList.contains('jw-icon-volume')) {
              e.preventDefault();
              e.stopPropagation();
              handleNewsDetail(headline, url);
            }
          }}
          onClick={(e) => {
            if (e.target.classList.contains('jw-icon-volume')) {
              e.stopPropagation();
            }
          }}
        >
          {showVideo ? (
            <JWPlayer
              config={{
                responsive: true,
                aspectratio: '16:9',
                displaytitle: false,
                displaydescription: false,
                allowFullscreen: false,
                autostart: 'viewable',
                stretching: 'uniform',
                pipIcon: 'disabled',
                mute: true,
                volume: 25,
                autoPause: {
                  viewability: true,
                  pauseAds: true,
                },
              }}
              playlist={
                mediaObj?.url?.includes('/manifests/')
                  ? `${environment.web_jwp_media_url}${mediaObj?.mediaId}`
                  : [{ file: mediaObj.url }]
              }
              library={environment.web_jwp_library_url}
            />
          ) : (
            <>
              <div className={classes.newsCardImageContainer}>
                {!mediaObj ? (
                  <img
                    src={isDarkMode ? noImageDark : noImage}
                    alt='No Image Found'
                    className={classes.newsCardImage}
                  />
                ) : (
                  <>
                    <img
                      src={
                        mediaObj.type === 'video'
                          ? mediaObj?.thumbUrl ||
                            (isDarkMode ? noImageDark : noImage)
                          : mediaObj?.url
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = isDarkMode ? noImageDark : noImage;
                      }}
                      alt='image'
                      className={classes.newsCardImage}
                    />
                    {mediaObj?.type === 'video' && (
                      <div className={classes.playIconPlaceholder}>
                        <img src={playIcon} alt='play-icon'></img>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          {imageCount > ONE && !showVideo && (
            <div className={classes.imageLabel}>
              <div aria-label='image-label'>
                <img
                  src={isDarkMode ? labelWhiteIconDark : labelWhiteIcon}
                  alt='more media'
                ></img>
              </div>
              <p className={classes.imageLabelText}>
                {imageCount} {MORE_LABEL}
              </p>
            </div>
          )}
        </div>
        <Box className={classes.newsCardBox}>
          <Box className={classes.newsCardContent}>
            <Typography variant='h2' className={classes.newsCardHeadline}>
              {headline ? parse(headline) : HEADLINE_FALLBACK}
            </Typography>
          </Box>
          <div className={classes.newsCardDetails}>
            {!history.pathname.endsWith(`-${locationID}`) && (
              <Box
                sx={{
                  backgroundColor: isDarkMode
                    ? (theme) => theme.palette.grey[100]
                    : '#F4F4F4',
                  border: '1px solid',
                  borderColor: isDarkMode
                    ? (theme) => theme.palette.grey[300]
                    : (theme) => theme.palette.grey[200],
                }}
                className={classes.newsCardLocation}
                onClick={handleDistrict}
              >
                <img
                  src={isDarkMode ? locationIconDark : locationIcon}
                  alt='location-icon'
                  className={classes.icons}
                ></img>
                <Typography
                  variant='subtitle5'
                  title={city ? city : NO_LOCATION}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {city ? city : NO_LOCATION}
                </Typography>
              </Box>
            )}

            {history.pathname !== mainCategoryUrl && (
              <Box
                sx={{
                  backgroundColor: isDarkMode
                    ? (theme) => theme.palette.grey[100]
                    : '#F4F4F4',
                  border: '1px solid',
                  borderColor: isDarkMode
                    ? (theme) => theme.palette.grey[300]
                    : (theme) => theme.palette.grey[200],
                }}
                className={classes.mainCategory}
                onClick={handleCategory}
              >
                {maincategoryIcon && (
                  <Typography component='span' sx={{ marginRight: '0.4rem' }}>
                    {maincategoryIcon}
                  </Typography>
                )}
                <Typography
                  title={mainCategory}
                  variant='subtitle5'
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {mainCategory}
                </Typography>
              </Box>
            )}
          </div>
        </Box>
        <Box
          sx={{
            backgroundColor: isDarkMode
              ? (theme) => theme.palette.grey[100]
              : '#F4F4F4',
          }}
          className={classes.newsCardActions}
        >
          <Box className={classes.newsCardReactionsBox}>
            <div className={classes.thumb}>
              <div
                aria-label='thumbs up'
                onClick={(event) => {
                  isAuthenticated
                    ? handleThumbUp(event)
                    : handleSignInModal(event);
                }}
              >
                {thumbState.isLiked ? (
                  <img
                    src={activeThumbUpIcon}
                    alt='active-thumb-up-icon'
                    className={classes.thumb}
                  />
                ) : (
                  <img
                    src={
                      isDarkMode ? inactiveThumbUpIconDark : inactiveThumbUpIcon
                    }
                    alt='inactive-thumb-up-icon'
                    className={classes.thumb}
                  />
                )}
              </div>

              <Typography
                variant='subtitle4'
                sx={{ fontSize: '0.875rem' }}
                className={classes.newsCardReactions}
              >
                {thumbState.likeCount ? thumbState.likeCount : ''}
              </Typography>
            </div>

            {/* <div className={classes.thumb}>
              <div
                aria-label='thumbs down'
                onClick={(event) => {
                  isAuthenticated
                    ? handleThumbDown(event)
                    : handleSignInModal(event);
                }}
              >
                {thumbState.isDisliked ? (
                  <img
                    src={activeThumbDownIcon}
                    alt='active-thumb-down-icon'
                    className={classes.thumb}
                  />
                ) : (
                  <img
                    src={
                      isDarkMode
                        ? inactiveThumbDownIconDark
                        : inactiveThumbDownIcon
                    }
                    alt='inactive-thumb-down-icon'
                    className={classes.thumb}
                  />
                )}
              </div>
              <Typography
                variant='subtitle4'
                sx={{ fontSize: '0.875rem' }}
                className={classes.newsCardReactions}
              >
                {thumbState.dislikeCount ? thumbState.dislikeCount : ''}
              </Typography>
            </div> */}

            <div className={classes.thumb}>
              <div
                aria-label='comment'
                onClick={(event) => {
                  isAuthenticated
                    ? handleComments(event, commentsCount, media)
                    : handleSignInModal(event);
                }}
              >
                <img
                  src={isDarkMode ? commentIconDark : commentIcon}
                  alt='comment-icon'
                  className={classes.thumb}
                />
              </div>
              <Typography
                variant='subtitle4'
                sx={{ fontSize: '0.875rem' }}
                className={classes.newsCardReactions}
              >
                {commentsCount ? commentsCount : ''}
              </Typography>
            </div>
          </Box>
        </Box>
      </Box>
      <ReportModal
        showReportModal={showReportModal}
        closeModal={closeModal}
        newsID={newsID}
      />

      {/* <DialogBox
        isOpen={showCommunityPopUp}
        onClose={() => setShowCommunityPopUp(false)}
        title={t('comment')}
        heading={t('share-with-community')}
        content={t(
          'news-content-needs-to-be-shared-with-community-to-enable-comments',
        )}
        primaryButtonAction={() => handlePostCreation(media)}
        primaryButtonText={t('proceed')}
        secondaryButtonAction={() => setShowCommunityPopUp(false)}
        secondaryButtonText={t('no-go-back')}
      /> */}

      <Menu
        id='news-card-menu'
        anchorEl={openNewsCardpopover}
        keepMounted
        open={Boolean(openNewsCardpopover)}
        onClose={() => setOpenNewsCardpopover(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            minWidth: '12.5rem',
            backgroundColor: isDarkMode ? '#323232' : '',
            border: isDarkMode ? '' : '1px solid #CCCCCC',
          },
          '& .MuiList-root': {
            py: '0.875rem',
            cursor: 'pointer',
          },
        }}
      >
        <ListItem
          aria-label='share'
          onClick={(event) => {
            handleShare(event);
            setOpenNewsCardpopover(null);
          }}
        >
          <img src={isDarkMode ? shareIconDark : shareIcon} alt='share-icon' />
          &nbsp;{t('Share')} &nbsp; &nbsp;
        </ListItem>

        <ListItem
          aria-label='bookmark'
          onClick={(event) => {
            isAuthenticated ? handleBookmark(event) : handleSignInModal(event);
            setOpenNewsCardpopover(null);
          }}
        >
          {bookmarkState ? (
            <img
              src={activeBookmarkIcon}
              alt='active-bookmark-icon'
              className={classes.icons}
            />
          ) : (
            <img
              src={isDarkMode ? inactiveBookmarkIconDark : inactiveBookmarkIcon}
              alt='inactive-bookmark-icon'
              className={classes.icons}
            />
          )}
          {bookmarkState ? t('REMOVE_BOOKMARK') : t('BOOKMARK')}
        </ListItem>
        {trackingID !== userID && !anonymous && (
          <ListItem
            aria-label='Follow Publisher'
            onClick={(event) => {
              isAuthenticated
                ? handleFollowUnfollowAuthor()
                : handleSignInModal(event);
              setOpenNewsCardpopover(null);
            }}
          >
            {isFollowedByUser ? (
              <img
                src={activeHeartIcon}
                alt='active-heart-icon'
                className={classes.icons}
              />
            ) : (
              <img
                src={isDarkMode ? inactiveHeartIconDark : inactiveHeartIcon}
                alt='inactive-heart-icon'
                className={classes.icons}
              />
            )}
            {userType === UGC_REPORTER &&
              trackingID !== userID &&
              (isFollowedByUser
                ? t('UNFOLLOW_REPORTER')
                : t('FOLLOW_REPORTER'))}
            {userType === PUBLISHER &&
              (isFollowedByUser
                ? t('UNFOLLOW_PUBLISHER')
                : t('FOLLOW_PUBLISHER'))}
          </ListItem>
        )}
        {trackingID !== userID && can_flag !== false && (
          <ListItem
            aria-label='flag'
            onClick={(event) => {
              if (isAuthenticated) {
                setShowReportModal(true);
                setOpenNewsCardpopover(null);
              } else {
                handleSignInModal(event);
              }
              setOpenNewsCardpopover(null);
            }}
          >
            <img
              src={isDarkMode ? flagIconDark : flagIcon}
              alt='flag-icon'
              className={
                isDarkMode
                  ? classes.popOverFlagIconDarkGrid
                  : classes.popOverFlagIcon
              }
            />
            {t('REPORT')}
          </ListItem>
        )}
      </Menu>

      {sharePopupOpen && (
        <ShareDialog
          media={media}
          headline={headline}
          authorName={authorName}
          url={`${window.location.origin}/news${url}`}
          open={sharePopupOpen}
          handleClose={handleSharePopup}
        />
      )}
      <InfoModal
        message={t('Looks like this post has been removed.')}
        open={isPrivatePost}
        close={() => {
          setIsPrivatePost(false);
        }}
      >
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={() => {
            setIsPrivatePost(false);
          }}
          sx={{
            maxWidth: '18.75rem',
            width: '100%',
            textTransform: 'none',
          }}
        >
          {t('okay')}
        </Button>
      </InfoModal>
    </>
  );
}
