import React, { useState, useEffect, useContext } from 'react';
import { Box } from '@mui/material';
import classes from './PromotionalNewsList.module.css';
import inactiveThumbUpIcon from '../../assets/icons/inactive-thumb-up-white.svg';
import activeThumbUpIcon from '../../assets/icons/active-thumb-up.svg';
// import inactiveThumbDownIcon from '../../assets/icons/inactive-thumb-down-white.svg';
// import activeThumbDownIcon from '../../assets/icons/activeHeartDislikeIconDarkMode.svg';
import commentIcon from '../../assets/icons/comment-white.svg';
import shareIcon from '../../assets/icons/share-white.svg';
import activeBookmarkIcon from '../../assets/icons/active-bookmark.svg';
import inactiveBookmarkIcon from '../../assets/icons/inactive-bookmark-white.svg';
import moreOptionsIcon from '../../assets/icons/more-options-white.svg';
import { useNavigate } from 'react-router-dom';
import noImage from '../../assets/icons/no-image.svg';
import noImageDark from '../../assets/icons/no-image-dark.svg';
import { PROMOTION_LABEL } from '../../Constants/Constants';
import { LIKE, DISLIKE, NONE } from '../../Constants/Constants';
import { UserDetailsProvider } from '../../store/user-details-context';
import newsService from '../../services/news.service';
import bookmarkService from '../../services/bookmark.service';
import { AppContext } from '../../store/app-context';
import { MinWidth } from '../../responsive/viewport.util';

export default function PromotionalNewsList(props) {
  const { dispatch: appCtxDispatch } = useContext(AppContext);
  const navigate = useNavigate();

  const [isBookmarkClicked, setIsBookmarkClicked] = useState(false);
  const [isImageError, setIsImageError] = useState(false);
  const [isReactionClicked, setIsReactionClicked] = useState(false);
  const [reaction, setReaction] = useState('');
  const [bookmarkState, setBookmarkState] = useState(false);
  const [removeBookmark, setRemoveBookmark] = useState(false);
  const [thumbState, setThumbState] = useState({
    thumbUpCount: props.likeCount,
    thumbDownCount: props.dislikeCount,
    thumbUpActive: false,
    thumbDownActive: false,
  });

  const [firstImage] = props.image;
  const newsID = props.newsId;
  const { state: userState } = useContext(UserDetailsProvider);
  const isAuthenticated = userState?.isAuthenticated;
  const newsSessionID = userState?.sessionId;
  const below1098 = MinWidth(1098);
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;

  const handleThumbUp = (event) => {
    event.stopPropagation();
    setIsReactionClicked(true);
    thumbState.thumbUpActive || props.isLiked
      ? setReaction(NONE)
      : setReaction(LIKE);
  };

  const handleThumbDown = (event) => {
    event.stopPropagation();
    setIsReactionClicked(true);
    thumbState.thumbDownActive || props.isDisliked
      ? setReaction(NONE)
      : setReaction(DISLIKE);
  };

  const handleComments = (event) => {
    event.stopPropagation();
  };

  const handleShare = (event) => {
    event.stopPropagation();
  };

  const handleBookmark = (event) => {
    event.stopPropagation();
    setIsBookmarkClicked(true);
    bookmarkState || props.isBookmark
      ? setRemoveBookmark(true)
      : setRemoveBookmark(false);
  };

  const handleMoreOptions = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    setThumbState((prev) => ({
      ...prev,
      thumbUpCount: props.likeCount,
      thumbDownCount: props.dislikeCount,
      thumbUpActive: props.isLiked,
      thumbDownActive: props.isDisliked,
    }));
  }, [props.isLiked, props.isDisliked]);

  useEffect(() => {
    setBookmarkState(props.isBookmark);
  }, [props.isBookmark]);

  useEffect(() => {
    if (isReactionClicked) {
      updateUserNews();
    }
  }, [isReactionClicked, newsID, reaction]);

  useEffect(() => {
    if (isBookmarkClicked) {
      bookmarkNews();
    }
  }, [isBookmarkClicked, newsID, removeBookmark]);

  const updateUserNews = async () => {
    try {
      const response = await newsService.updateUserNews({
        sessionId: newsSessionID,
        newsId: newsID,
        type: reaction,
      });
      response?.news?.forEach((updatedNews) => {
        setThumbState({
          thumbUpCount: updatedNews.news_like_count,
          thumbUpActive: updatedNews.is_liked,
          thumbDownCount: updatedNews.news_dislike_count,
          thumbDownActive: updatedNews.is_disliked,
        });
        setIsReactionClicked(false);
      });
    } catch (error) {}
  };

  const bookmarkNews = async () => {
    try {
      const response = await bookmarkService.removeBookmarks({
        sessionId: newsSessionID,
        newsId: newsID,
        remove: removeBookmark,
      });
      response?.news?.forEach((bookmarkedNews) => {
        setBookmarkState(bookmarkedNews.is_bookmark);
        setIsBookmarkClicked(false);
      });
    } catch (error) {}
  };

  const handleSignInModal = (event) => {
    event.stopPropagation();
    appCtxDispatch({ type: 'SHOW_LOGIN_REQUIRED' });
  };

  const handleNewsDetail = (newsId) => {
    navigate(`/news-details/${newsId}`);
  };

  return (
    <Box
      className={classes.newsCard}
      onClick={() => handleNewsDetail(props?.newsId)}
    >
      <Box className={classes.newsCardMedia}>
        {isImageError ? (
          <img
            src={isDarkMode ? noImageDark : noImage}
            alt='No Image Found'
            className={classes.newsCardImage}
            style={{ height: '13.25rem' }}
          />
        ) : (
          <img
            src={firstImage}
            onError={() => setIsImageError(true)}
            alt='image'
            className={classes.newsCardImage}
          />
        )}
        <div className={classes.imageLabel}>
          <p className={classes.imageLabelText}>{PROMOTION_LABEL}</p>
        </div>
        <Box className={classes.newsCardActions}>
          <Box className={classes.newsCardReactionsBox}>
            <div className={classes.thumb}>
              <div
                aria-label='thumbs up'
                onClick={(event) => {
                  isAuthenticated
                    ? handleThumbUp(event)
                    : handleSignInModal(event);
                }}
              >
                {thumbState.thumbUpActive ? (
                  <img
                    src={activeThumbUpIcon}
                    alt='active-thumb-up-icon'
                    className={classes.thumb}
                  />
                ) : (
                  <img
                    src={inactiveThumbUpIcon}
                    alt='inactive-thumb-up-icon'
                    className={classes.thumb}
                  />
                )}
              </div>

              <div className={classes.newsCardReactions}>
                {thumbState.thumbUpCount ? thumbState.thumbUpCount : ''}
              </div>
            </div>

            {/* <div className={classes.thumb}>
              <div
                aria-label='thumbs down'
                onClick={(event) => {
                  isAuthenticated
                    ? handleThumbDown(event)
                    : handleSignInModal(event);
                }}
              >
                {thumbState.thumbDownActive ? (
                  <img
                    src={activeThumbDownIcon}
                    alt='active-thumb-down-icon'
                    className={classes.thumb}
                  />
                ) : (
                  <img
                    src={inactiveThumbDownIcon}
                    alt='inactive-thumb-down-icon'
                    className={classes.thumb}
                  />
                )}
              </div>
              <div className={classes.newsCardReactions}>
                {thumbState.thumbDownCount ? thumbState.thumbDownCount : ''}
              </div>
            </div> */}

            <div className={classes.thumb}>
              <div
                aria-label='comment'
                onClick={(event) => {
                  isAuthenticated
                    ? handleComments(event)
                    : handleSignInModal(event);
                }}
              >
                <img
                  src={commentIcon}
                  alt='comment-icon'
                  className={classes.thumb}
                />
              </div>
              <div className={classes.newsCardReactions}></div>
            </div>
          </Box>
          {below1098 && (
            <Box className={classes.newsCardExtraActionsBox}>
              <div
                aria-label='share'
                onClick={(event) => {
                  isAuthenticated
                    ? handleShare(event)
                    : handleSignInModal(event);
                }}
              >
                <img src={shareIcon} alt='share-icon' />
              </div>
              <div
                aria-label='bookmark'
                onClick={(event) => {
                  isAuthenticated
                    ? handleBookmark(event)
                    : handleSignInModal(event);
                }}
              >
                {bookmarkState ? (
                  <img src={activeBookmarkIcon} alt='active-bookmark-icon' />
                ) : (
                  <img
                    src={inactiveBookmarkIcon}
                    alt='inactive-bookmark-icon'
                  />
                )}
              </div>
              <div
                aria-label='more'
                onClick={(event) => {
                  isAuthenticated
                    ? handleMoreOptions(event)
                    : handleSignInModal(event);
                }}
              >
                <img src={moreOptionsIcon} alt='more-options-icon' />
              </div>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
