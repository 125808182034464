import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Box,
  Menu,
  ListItem,
  Typography,
  Avatar,
  IconButton,
  Button,
} from '@mui/material';
import inactiveThumbUpIcon from '../../assets/icons/inactive-thumb-up.svg';
import inactiveThumbUpIconDark from '../../assets/icons/inactive-thumb-up-white.svg';
import activeHeartDarkMode from '../../assets/icons/active-thumb-up.svg';
import activeHeartIconlighMode from '../../assets/icons/active-thumb-light.svg';
import inactiveThumbDownIcon from '../../assets/icons/inactive-thumb-down.svg';
import inactiveThumbDownIconDark from '../../assets/icons/inactive-thumb-down-white.svg';
// import activeDislikeIconDarkMode from '../../assets/icons/activeHeartDislikeIconDarkMode.svg';
// import activeDislikeIconLightMode from '../../assets/icons/activeHeartDislikeIconLightMode.svg';
import classes from './NewsCard.module.css';
import commentIconDarkMode from '../../assets/icons/commentIconDarkMode.svg';
import commentIconLightMode from '../../assets/icons/commentIconLighMode.svg';
import activeBookmarkIcon from '../../assets/icons/active-bookmark.svg';
import inactiveBookmarkIcon from '../../assets/icons/inactive-bookmark.svg';
import inactiveBookmarkIconDark from '../../assets/icons/Inactive-Bookmark-Dark.svg';
import MoreVerIcon from '../../assets/icons/moreVertIcon';
import labelWhiteIcon from '../../assets/icons/label-white.svg';
import labelWhiteIconDark from '../../assets/icons/StackDark.svg';
import locationIcon from '../../assets/icons/Location.svg';
import locationIconDark from '../../assets/icons/location-dark.svg';
import activeHeartIcon from '../../assets/icons/active-heart.svg';
import inactiveHeartIcon from '../../assets/icons/inactive-heart.svg';
import inActiveHeartIconDark from '../../assets/icons/inactive-heart-dark.svg';
import flagIcon from '../../assets/icons/flag.svg';
import flagIconDark from '../../assets/icons/Flag-dark.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import noImage from '../../assets/icons/no-image.svg';
import noImageDark from '../../assets/icons/no-image-dark.svg';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import add from 'date-fns/add';
import ReportModal from '../Report/ReportModal';
import { useTranslation } from 'react-i18next';
import JWPlayer from '@jwplayer/jwplayer-react';
import playIcon from '../../assets/icons/play.svg';
import bluetickIcon from '../../assets/icons/blue-influencer-tick-icon.svg';
import purpletickIcon from '../../assets/icons/purple-tick.svg';
import newsService from '../../services/news.service';
import { useMetaStore } from '../../store/MetaTagHandler';
import { metaAction } from '../../store/MetaTagHandler/metaAction';
import ShareDialog from '../share-modal/ShareModal';
import reportersLogo from '../../assets/defaultProfilePic.png';
import shareIcon from '../../assets/icons/share.svg';
import shareIconDark from '../../assets/icons/share-white.svg';
import { MaxWidth, viewPort, MinWidth } from '../../responsive/viewport.util';
import {
  HEADLINE_FALLBACK,
  DESCRIPTION_FALLBACK,
  MORE_LABEL,
  NO_LOCATION,
  // PUBLISHER,
  ONE,
  TIME_UNITS,
  FIVE,
  THIRTY,
  UGC_REPORTER,
  // TRAINING_LANGUAGE,
  LIKE,
  DISLIKE,
  NONE,
  LIKE_NONE,
  DISLIKE_NONE,
  AUTHOR_NEWS,
  // CITIZEN_FEED,
  // AGENCY,
  INFLUENCER,
  CITIZEN_REPORTER_CAPITAL,
  PUBLISHER_TYPE,
  // USER,
} from '../../Constants/Constants';
import './NewsCard.css';
import { UserDetailsProvider } from '../../store/user-details-context';
import bookmarkService from '../../services/bookmark.service';
import userProfileService from '../../services/user-profile.service';
import { AppContext } from '../../store/app-context';
import InfoModal from '../Modal/InfoModal';
import anonymousIcon from '../../assets/icons/anonymous.svg';
import { EnvironmentProvider } from '../../store/firebase-context';
import { getReactionStatus } from '../../utils/news-card.util';
import parse from 'html-react-parser';
import NewsDescription from '../News/NewsDescription';
import Comments from '../comments/Comments';
import { MINMINI_V3 } from '../../themes/color.constant';
import warningBubbleIcon from '../../assets/icons/warning-bubble.svg';
import warningBubbleLightIcon from '../../assets/icons/warning-bubble-light.svg';
import WarningModal from '../Modal/WarningModal';
import Carousel from '../News/Carousel';
import recombeeService from '../../services/recombee.service';

/**
 * NewsCard Component
 */
export default function NewsCard(props) {
  const {
    url,
    maincategoryIcon,
    mainCategory,
    mainCategoryUrl,
    headline,
    media,
    city,
    story,
    newsDate,
    likeCount,
    dislikeCount,
    authorName,
    authorID,
    trackingID,
    newsID,
    authorImage,
    isLiked,
    isDisliked,
    isBookmark,
    // isTrending,
    // newsType,
    commentsCount,
    locationID,
    hasVideo,
    verifiedByDT,
    onRemoveBookmark,
    onCardAction,
    anonymous,
    can_flag,
    user_type,
    isDetailPage,
    keywords,
  } = props;
  //share popup state
  const [sharePopupOpen, setSharePopup] = useState(false);
  /**
   * we can openand close popup via shareHandle
   */
  const handleSharePopup = () => {
    setSharePopup(!sharePopupOpen);
  };
  const isDarkMode = localStorage.getItem('theme') === 'Dark' ? true : false;
  const dispatchMetaAction = useMetaStore()[1];
  const {
    // state: appCtxState,
    dispatch: appCtxDispatch,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [isBookmarkClicked, setIsBookmarkClicked] = useState(false);
  const [isReactionClicked, setIsReactionClicked] = useState(false);
  const [reaction, setReaction] = useState('');
  const [bookmarkState, setBookmarkState] = useState(false);
  const [removeBookmark, setRemoveBookmark] = useState(false);
  const [thumbState, setThumbState] = useState({
    likeCount,
    dislikeCount,
    isLiked,
    isDisliked,
  });
  const [isPrivatePost, setIsPrivatePost] = useState(false);
  const { state: userState } = useContext(UserDetailsProvider);
  const { environment } = useContext(EnvironmentProvider);
  const isAuthenticated = userState?.isAuthenticated;
  const follows = userState?.follows;
  const userID = userState?.userDetails?.userId;
  // const location = useLocation();
  const { t } = useTranslation();
  const location = useLocation();
  const [showWarningModal, setShowWarningModal] = useState(false);

  const [openNewsCardpopover, setOpenNewsCardpopover] = useState(null);
  const [isFollowedByUser, setIsFollowedByUser] = useState(false);
  // const [showCommunityPopUp, setShowCommunityPopUp] = useState(false);

  const imageCount = media ? media.length - 1 : 0;
  const newsSessionID = userState?.sessionId;
  const history = useLocation();

  const [showReportModal, setShowReportModal] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const mediaObj = media?.length ? media[0] : null;
  const commentsRef = useRef(null);
  const isMobile = MaxWidth(viewPort.tablet);
  const belowLap = MaxWidth(viewPort.smallLaptop);
  const aboveLap = MinWidth(viewPort.smallLaptop);
  const aboveMobile = MinWidth(viewPort.mobileMax);
  const [player, setPlayer] = useState(null);

  /**
   * Checking the news type from news V2 api response and setting up the user type as publisher or reporter
   */
  // let userType;
  // if (newsType === AGENCY) {
  //   userType = PUBLISHER;
  // } else if (newsType === INFLUENCER) {
  //   userType = INFLUENCER;
  // } else if (newsType === CITIZEN_FEED) {
  //   userType = UGC_REPORTER;
  // }

  useEffect(() => {
    if (location.hash === '#comments') {
      handleCommentsScrollDetail();
    }
  }, [location]);

  const handleCommentsScrollDetail = () => {
    commentsRef?.current?.scrollIntoView({
      behaviour: 'smooth',
      block: 'center',
    });
  };

  const handleThumbUp = (event) => {
    event.stopPropagation();
    setIsReactionClicked(true);
    thumbState.isLiked || isLiked ? setReaction(LIKE_NONE) : setReaction(LIKE);
  };

  const handleThumbDown = (event) => {
    event.stopPropagation();
    setIsReactionClicked(true);
    thumbState.isDisliked || isDisliked
      ? setReaction(DISLIKE_NONE)
      : setReaction(DISLIKE);
  };

  /**
   * to open or close share popup
   * @param {event}  onclick event
   */
  const handleShare = (event) => {
    handleSharePopup();
    if (isAuthenticated) {
      captureRecombeeEvents('share');
    }
    event.stopPropagation();
  };

  const handleBookmark = () => {
    setIsBookmarkClicked(true);
    bookmarkState || isBookmark
      ? setRemoveBookmark(true)
      : setRemoveBookmark(false);
  };

  const handleMoreOptions = (event) => {
    event.stopPropagation();
    setOpenNewsCardpopover(event.currentTarget);
  };

  const handleDistrict = (event) => {
    event?.stopPropagation();
    navigate(`/districts/${city}-${locationID}`);
  };

  const handleCategory = (event) => {
    event?.stopPropagation();
    navigate(mainCategoryUrl);
  };

  /**
   * Handles the follow/unfollow action for a user (author, influencer, or publisher).
   *
   * @async
   * @function
   * @name handleFollowUnfollowAuthor
   * @throws {Error} If an error occurs during the follow/unfollow operation.
   * @returns {Promise<void>} A promise that resolves after the follow/unfollow operation is completed.
   *
   * @description
   * This function constructs followData object with user information and sends a follow or unfollow request to the userProfileService.
   *
   */
  const handleFollowUnfollowAuthor = async () => {
    try {
      const followData = {
        userId: userID,
      };
      if (isFollowedByUser) {
        followData['unfollow'] = JSON.stringify({ IDs: [trackingID] });
      } else {
        followData['follow'] = JSON.stringify({ IDs: [trackingID] });
      }
      await userProfileService.updateUserFollow(followData);
    } catch (error) {}
  };

  /**
   * close Report Modal
   */
  const closeModal = () => {
    setShowReportModal(false);
  };

  useEffect(() => {
    setThumbState((prev) => ({
      ...prev,
      likeCount,
      dislikeCount,
      isLiked,
      isDisliked,
    }));
  }, [isLiked, isDisliked, likeCount, dislikeCount]);

  useEffect(() => {
    setBookmarkState(isBookmark);
  }, [isBookmark]);

  useEffect(() => {
    if (isReactionClicked) {
      updateUserNews();
    }
  }, [isReactionClicked, newsID, reaction]);

  useEffect(() => {
    if (isBookmarkClicked) {
      bookmarkNews();
    }
  }, [isBookmarkClicked, newsID, removeBookmark]);

  /**
   * calling the updateFollowButton if its not the first load of the fetchPreferences API and if there is change in author state, authorID and trackingID
   */
  useEffect(() => {
    updateFollowButton();
  }, [authorID, trackingID]);

  /**
   * Updates the state of the follow button based on whether the user is already following the author.
   *
   * @function
   * @name updateFollowButton
   * @returns {void}
   *
   * @description
   * This function checks if the author (identified by the trackingID) is present in the follows array,
   * and accordingly updates the state variable isFollowedByUser to control the state of the follow button.
   *
   */
  const updateFollowButton = () => {
    const isExistingAuthor = follows?.some((author) => author === trackingID);

    isExistingAuthor ? setIsFollowedByUser(true) : setIsFollowedByUser(false);
  };

  /**
   * SideEffect to run update the follow/unfollow button based upon the updated data by graphQL subscription
   */
  useEffect(() => {
    follows?.length && updateFollowButton();
  }, [follows]);

  const updateUserNews = async () => {
    try {
      const response = await newsService.updateUserNews({
        sessionId: newsSessionID,
        newsId: newsID,
        type:
          reaction === LIKE_NONE || reaction === DISLIKE_NONE ? NONE : reaction,
      });

      if (response?.errorMessage) {
        setIsPrivatePost(true);
      }

      const { isLiked, likeCount, isDisliked, dislikeCount } =
        getReactionStatus({ ...thumbState }, reaction);

      setThumbState({
        likeCount: likeCount,
        isLiked: isLiked,
        dislikeCount: dislikeCount,
        isDisliked: isDisliked,
      });

      onCardAction &&
        onCardAction({
          newsId: newsID,
          news_like_count: likeCount,
          is_liked: isLiked,
          news_dislike_count: dislikeCount,
          is_disliked: isDisliked,
        });

      setIsReactionClicked(false);
    } catch (error) {}
  };

  const bookmarkNews = async () => {
    try {
      const response = await bookmarkService.removeBookmarks({
        sessionId: newsSessionID,
        newsId: newsID,
        remove: removeBookmark,
      });
      if (response) {
        setBookmarkState(!removeBookmark);
        onCardAction &&
          onCardAction({
            newsId: newsID,
            is_bookmark: !removeBookmark,
          });
        setIsBookmarkClicked(false);
        removeBookmark && onRemoveBookmark && onRemoveBookmark(newsID);
      }
    } catch (error) {}
  };

  const handleNewsDetail = (headLine, url) => {
    if (!isDetailPage) {
      const metaObj = {
        title: headLine,
        description: headLine,
        keywords: keywords || '',
        canonical_url: `/news${url}`,
      };
      dispatchMetaAction(metaAction.updateMeta, metaObj);
      navigate(`/news${url}`);
    }
  };

  const handleCommentsScroll = (headLine, url, event) => {
    event.stopPropagation();
    const URL = '/news' + url + '#comments';
    const metaObj = {
      title: headLine,
      description: headLine,
      keywords: '',
      canonical_url: URL,
    };
    dispatchMetaAction(metaAction.updateMeta, metaObj);
    navigate(URL);
  };

  const handleAuthor = (event) => {
    event.stopPropagation();
    if (user_type === INFLUENCER && !trackingID) {
    } else {
      const metaObj = {
        title: authorName,
        description: authorName,
        keywords: '',
        canonical_url: `/${AUTHOR_NEWS}/${authorName}`,
      };
      dispatchMetaAction(metaAction.updateMeta, metaObj);
      navigate(`/${AUTHOR_NEWS}/${authorName}`);
    }
  };

  const handleSignInModal = (event) => {
    event?.stopPropagation();
    appCtxDispatch({ type: 'SHOW_LOGIN_REQUIRED' });
  };

  const timeInIST =
    newsDate &&
    add(new Date(newsDate), {
      hours: FIVE,
      minutes: THIRTY,
    });

  const timeInHours =
    timeInIST &&
    formatDistanceToNowStrict(timeInIST)
      .replace(TIME_UNITS, '')
      .split(' ')
      .join('');

  // const handlePostCreation = (media) => {};

  /**
   * News Card Focus Handler
   */
  const onCardFocus = () => {
    hasVideo && mediaObj?.jwp_supported && setShowVideo(true);
  };

  /**
   * News Card Blur Handler
   */
  const onCardBlur = () => {
    hasVideo && mediaObj?.jwp_supported && setShowVideo(false);
    showVideo && isAuthenticated && captureRecombeeEvents('JWP');
  };

  const handleJWPMount = ({ player }) => {
    setPlayer(player);
  };

  /**
   * Captures a Recombee interaction event
   * @async
   * @function captureRecombeeEvents
   * @throws {Error} If an error occurs while capturing the interaction.
   * @returns {Promise<void>}
   */
  const captureRecombeeEvents = async (eventType) => {
    try {
      const payload = {
        eventName: null,
        userId: userID,
        itemId: parseInt(newsID),
      };

      if (eventType === 'share') {
        payload['eventName'] = 'purchase';
      } else if (eventType === 'JWP') {
        const playerPlaybackPercent =
          player.getPosition() / player.getDuration();
        payload['eventName'] = 'viewPortion';
        payload['portion'] = playerPlaybackPercent.toFixed(1);
      }

      await recombeeService.captureRecombeeInteraction(payload);
    } catch (error) {}
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: isDetailPage ? 'column' : 'row',
        width: '100%',
      }}
    >
      <Box
        sx={{
          backgroundColor: isDarkMode
            ? (theme) => theme.palette.grey.bgDark
            : (theme) => theme.palette.background.white,
          border: isDetailPage && isMobile ? 'none' : ' 1px solid',
          borderColor: isDarkMode
            ? MINMINI_V3.grey.darkGrey
            : MINMINI_V3.primary.offWhite,
          width: '100%',
          maxWidth:
            aboveLap || (belowLap && aboveMobile) ? '32.5rem' : '28.875rem',
          maxHeight: isDetailPage
            ? 'none'
            : aboveLap
            ? '47.5rem'
            : belowLap && aboveMobile
            ? '42.188rem'
            : '39.375rem',
        }}
        className={classes.newsCard}
        onClick={() => handleNewsDetail(headline, url)}
        onMouseEnter={onCardFocus}
        onMouseLeave={onCardBlur}
      >
        <Box
          className={classes.newsCardHeaderContainer}
          sx={{
            padding: aboveLap
              ? '1.1rem 1.5rem'
              : belowLap && aboveMobile
              ? '1.1rem 1.5rem'
              : '1.1rem 0.875rem',
            height: aboveLap
              ? '4.438rem'
              : belowLap && aboveMobile
              ? '4.188rem'
              : '2.75rem',
          }}
          onClick={(e) => {
            if (!anonymous) {
              handleAuthor(e);
            }
          }}
        >
          {anonymous ? (
            <img
              src={anonymousIcon}
              className={
                isDarkMode
                  ? classes.authorAnonymousCircleDark
                  : classes.authorAnonymousCircle
              }
              alt='anonymous-post'
            />
          ) : (
            <>
              <Avatar
                src={authorImage}
                className={classes.authorImgCircle}
                alt='author-img'
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = reportersLogo;
                }}
                sx={{
                  width: isMobile ? '1.3rem' : '2.25rem',
                  height: isMobile ? '1.3rem' : '2.25rem',
                  cursor: 'pointer',
                  // position: 'relative',
                  borderRadius: '50%',
                  color: (theme) => theme.palette.white[100],
                  fontSize: isMobile ? '0.5rem' : '1rem',
                  fontWeight: '600',
                  backgroundColor: authorName
                    ? (theme) => theme.palette.avatar[100]
                    : 'unset',
                }}
              >
                {authorName ? (
                  `${(
                    authorName?.charAt(0) || ''
                  ).toUpperCase()}${(authorName?.indexOf(' ') !== -1
                    ? authorName?.charAt(authorName.indexOf(' ') + 1)
                    : ''
                  )?.toUpperCase()}`
                ) : (
                  <img src={reportersLogo} alt='reporters-logo' />
                )}
              </Avatar>
            </>
          )}
          <div className={classes.newsCardTitle}>
            <Typography
              variant={isMobile ? 'h8_mobile' : 'h8'}
              sx={{
                display: 'block',
                fontWeight: 600,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                title: anonymous
                  ? `${t('anonymous')}`
                  : authorName
                  ? `${authorName}`
                  : '',
                fontSize: aboveLap
                  ? '1.118rem'
                  : belowLap && aboveMobile
                  ? '1.063rem'
                  : '0.813rem',
              }}
            >
              {anonymous ? t('anonymous') : authorName ? authorName : ''}
            </Typography>
          </div>
          {user_type === PUBLISHER_TYPE && (
            <Box
              component='img'
              src={bluetickIcon}
              alt='blue-tick'
              width={isMobile ? 15 : 24}
              sx={{
                height: 'auto',
              }}
            ></Box>
          )}
          {user_type === INFLUENCER && (
            <Box
              component='img'
              src={bluetickIcon}
              alt='blue-tick'
              width={isMobile ? 15 : 24}
              sx={{
                height: 'auto',
              }}
            ></Box>
          )}
          {user_type === UGC_REPORTER && (
            <Box
              component='img'
              src={bluetickIcon}
              alt='reporter'
              width={isMobile ? 15 : 24}
              sx={{
                height: 'auto',
              }}
            ></Box>
          )}
          {user_type === CITIZEN_REPORTER_CAPITAL && (
            <Box
              component='img'
              src={purpletickIcon}
              alt='reporter'
              width={isMobile ? 15 : 24}
              sx={{
                height: 'auto',
              }}
            ></Box>
          )}

          <Typography
            variant='subtitle4'
            sx={{
              color: isDarkMode
                ? MINMINI_V3.primary.offWhite
                : MINMINI_V3.grey.midGrey,
              // fontSize: isMobile ? '0.625rem' : '1rem',
              fontSize: aboveLap
                ? '0.875rem'
                : belowLap && aboveMobile
                ? '0.813rem'
                : '0.625rem',
              ml: isMobile ? '0.375rem' : '0.625rem',
            }}
            className={classes.hours}
          >
            {timeInHours ? timeInHours : ''}
          </Typography>
        </Box>

        {isDetailPage ? (
          <Box
            sx={{ position: 'relative' }}
            onClick={(e) => e?.stopPropagation()}
          >
            <Box
              sx={{
                width: '100%',
                position: 'absolute',
                top: '0.688rem',
                right: '0.75rem',
                display: 'flex',
                justifyContent: 'end',
                zIndex: 1,
              }}
            >
              {user_type === UGC_REPORTER &&
                verifiedByDT === false &&
                media[0]?.isDT === false && (
                  <img
                    src={
                      isDarkMode ? warningBubbleIcon : warningBubbleLightIcon
                    }
                    width={20}
                    height={21}
                    alt='warning-bubble'
                    onClick={() => setShowWarningModal(true)}
                  />
                )}
            </Box>
            {showWarningModal && (
              <WarningModal
                isOpen={showWarningModal}
                onClose={() => setShowWarningModal(false)}
                image={isDarkMode ? warningBubbleIcon : warningBubbleLightIcon}
                message={t('media-cannot-be-captured')}
              />
            )}
            <Carousel
              media={media}
              userID={userID}
              newsID={newsID}
              isAuthenticated={isAuthenticated}
            />
          </Box>
        ) : (
          <div
            className={`news-card-player ${classes.newsCardMedia}`}
            onClickCapture={(e) => {
              if (!e.target.classList.contains('jw-icon-volume')) {
                e.preventDefault();
                e.stopPropagation();
                handleNewsDetail(headline, url);
              }
            }}
            onClick={(e) => {
              if (e.target.classList.contains('jw-icon-volume')) {
                e.stopPropagation();
              }
            }}
          >
            {showVideo ? (
              <JWPlayer
                config={{
                  responsive: true,
                  aspectratio: '16:9',
                  displaytitle: false,
                  displaydescription: false,
                  allowFullscreen: false,
                  autostart: 'viewable',
                  stretching: 'uniform',
                  pipIcon: 'disabled',
                  mute: true,
                  volume: 25,
                  autoPause: {
                    viewability: true,
                    pauseAds: true,
                  },
                }}
                didMountCallback={handleJWPMount}
                playlist={
                  mediaObj?.url?.includes('/manifests/')
                    ? `${environment.web_jwp_media_url}${mediaObj?.mediaId}`
                    : [{ file: mediaObj.url }]
                }
                library={environment.web_jwp_library_url}
              />
            ) : (
              <>
                {!mediaObj ? (
                  <img
                    src={isDarkMode ? noImageDark : noImage}
                    alt='No Image Found'
                    className={classes.newsCardImage}
                  />
                ) : (
                  <>
                    <img
                      src={
                        mediaObj.type === 'video'
                          ? mediaObj?.thumbUrl ||
                            (isDarkMode ? noImageDark : noImage)
                          : mediaObj?.url
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = isDarkMode ? noImageDark : noImage;
                      }}
                      alt='image'
                      className={classes.newsCardImage}
                    />
                    {mediaObj?.type === 'video' && (
                      <div className={classes.playIconPlaceholder}>
                        <img src={playIcon} alt='play-icon'></img>
                      </div>
                    )}
                  </>
                )}
              </>
            )}

            {imageCount > ONE && (
              <Box
                sx={{
                  backgroundColor: isDarkMode
                    ? (theme) => theme.palette.grey[100]
                    : (theme) => theme.palette.grey[600],
                }}
                className={classes.imageLabel}
              >
                <div aria-label='image-label'>
                  <img
                    src={isDarkMode ? labelWhiteIconDark : labelWhiteIcon}
                    alt='more media'
                  ></img>
                </div>
                <p className={classes.imageLabelText}>
                  {imageCount} {MORE_LABEL}
                </p>
              </Box>
            )}
          </div>
        )}
        <Box
          className={classes.newsCardBox}
          sx={
            {
              // maxHeight: breakPointbasedValue('9.25rem', '9.25rem', '7.375rem'),
            }
          }
        >
          <Box
            sx={{
              padding: aboveLap
                ? '1.1rem 0rem 1.1rem 1.5rem'
                : belowLap && aboveMobile
                ? '1.1rem 0rem 1.1rem 1.5rem'
                : '0.875rem 0rem 0.875rem 1.25rem',
            }}
            className={classes.newsCardContent}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                sx={{
                  fontSize: aboveLap
                    ? '1.25rem'
                    : belowLap && aboveMobile
                    ? '1.125rem'
                    : '1rem',
                }}
                variant='h2'
                className={
                  isDetailPage
                    ? classes.newCardHeadLine_detail
                    : classes.newsCardHeadline
                }
              >
                {headline ? parse(headline) : HEADLINE_FALLBACK}
              </Typography>
              <div className={classes.headerActionsContainer}>
                {/* {isTrending && (
                  <img
                    src={isDarkMode ? inactiveFireIconDark : inactiveFireIcon}
                    alt='active-fire-icon'
                    className={classes.icons}
                    onClick={(event) => event.stopPropagation()}
                  />
                )} */}
                <IconButton
                  onClick={(event) => {
                    handleMoreOptions(event);
                  }}
                >
                  <MoreVerIcon
                    fill={'none'}
                    stroke={
                      isDarkMode
                        ? MINMINI_V3.primary.white
                        : MINMINI_V3.secondary.blueHue
                    }
                  />
                </IconButton>
              </div>
            </Box>

            <div className={classes.newsCardDetails}>
              {!history.pathname.endsWith(`-${locationID}`) &&
                !city?.includes('Other') && (
                  <Box
                    sx={{
                      backgroundColor: isDarkMode
                        ? MINMINI_V3.grey.darkGrey
                        : MINMINI_V3.primary.White,
                      border: '1px solid',
                      borderColor: isDarkMode
                        ? (theme) => theme.palette.grey[300]
                        : (theme) => MINMINI_V3.grey.grey,
                    }}
                    className={classes.newsCardLocation}
                    onClick={handleDistrict}
                  >
                    <img
                      src={isDarkMode ? locationIconDark : locationIcon}
                      alt='location-icon'
                      className={classes.icons}
                    ></img>
                    <Typography
                      variant='subtitle5'
                      title={city ? city : NO_LOCATION}
                      sx={{
                        fontSize: aboveLap
                          ? '0.875rem'
                          : belowLap && aboveMobile
                          ? '0.813rem'
                          : '0.625rem',
                        color: isDarkMode
                          ? MINMINI_V3.primary.white
                          : MINMINI_V3.secondary.voilet,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        wordBreak: 'break-all',
                      }}
                    >
                      {city ? city : NO_LOCATION}
                    </Typography>
                  </Box>
                )}

              {history.pathname !== mainCategoryUrl ? (
                <Box
                  sx={{
                    backgroundColor: isDarkMode
                      ? MINMINI_V3.grey.darkGrey
                      : MINMINI_V3.primary.White,
                    border: '1px solid',
                    borderColor: isDarkMode
                      ? (theme) => theme.palette.grey[300]
                      : (theme) => MINMINI_V3.grey.grey,
                  }}
                  className={classes.mainCategory}
                  onClick={handleCategory}
                >
                  {maincategoryIcon && (
                    <Typography component='span' sx={{ marginRight: '0.4rem' }}>
                      {maincategoryIcon}
                    </Typography>
                  )}
                  <Typography
                    variant='subtitle5'
                    title={mainCategory}
                    sx={{
                      fontSize: aboveLap
                        ? '0.875rem'
                        : belowLap && aboveMobile
                        ? '0.813rem'
                        : '0.625rem',
                      color: isDarkMode
                        ? MINMINI_V3.primary.white
                        : MINMINI_V3.secondary.voilet,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      wordBreak: 'break-all',
                    }}
                  >
                    {mainCategory}
                  </Typography>
                </Box>
              ) : (
                <Box className={classes.mainCategory}></Box>
              )}
            </div>
            <Box
              sx={{
                backgroundColor: (theme) =>
                  isDarkMode ? theme.palette.grey.bgDark : '',
              }}
              className={classes.newsCardActions}
            >
              <Box
                sx={{
                  py: aboveLap
                    ? '0.5rem'
                    : belowLap && aboveMobile
                    ? '0.5rem'
                    : 'none',
                }}
                className={classes.newsCardReactionsBox}
              >
                <div className={classes.thumb}>
                  {/* <IconButton
                    onClick={(event) => {
                      isAuthenticated
                        ? handleThumbUp(event)
                        : handleSignInModal(event);
                    }}
                  >
                    <HeartLikeIcon
                      fill={'none'}
                      stroke={
                        thumbState.isLiked
                          ? MINMINI_V3.primary.purple
                          : isDarkMode
                          ? MINMINI_V3.primary.white
                          : MINMINI_V3.grey.coolGray
                      }
                    />
                  </IconButton> */}

                  <div
                    aria-label='thumbs up'
                    onClick={(event) => {
                      isAuthenticated
                        ? handleThumbUp(event)
                        : handleSignInModal(event);
                    }}
                  >
                    {thumbState.isLiked ? (
                      <img
                        style={{
                          width: aboveLap ? '1.563rem' : '1.4rem',
                          height: aboveLap ? '1.563rem' : '1.4rem',
                          marginRight: '0.313rem',
                        }}
                        src={
                          isDarkMode
                            ? activeHeartDarkMode
                            : activeHeartIconlighMode
                        }
                        alt='active-thumb-up-icon'
                        className={classes.thumb}
                      />
                    ) : (
                      <img
                        style={{
                          width: aboveLap ? '1.563rem' : '1.4rem',
                          height: aboveLap ? '1.563rem' : '1.4rem',
                          marginRight: '0.313rem',
                        }}
                        src={
                          isDarkMode
                            ? inactiveThumbUpIconDark
                            : inactiveThumbUpIcon
                        }
                        alt='inactive-thumb-up-icon'
                        className={classes.thumb}
                      />
                    )}
                  </div>
                  <Typography
                    variant='subtitle4'
                    sx={{ fontSize: '0.875rem' }}
                    className={classes.newsCardReactions}
                  >
                    {thumbState.likeCount ? thumbState.likeCount : ''}
                  </Typography>
                </div>
                {/* <div className={classes.thumb}>
                  <IconButton
                    onClick={(event) => {
                      isAuthenticated
                        ? handleThumbDown(event)
                        : handleSignInModal(event);
                    }}
                  > */}
                {/* <HeartDisLikeIcon
                      fill={'none'}
                      stroke={
                        thumbState.isDisliked
                          ? MINMINI_V3.primary.errorRed
                          : isDarkMode
                          ? MINMINI_V3.primary.white
                          : MINMINI_V3.grey.coolGray
                      }
                    />
                  </IconButton>
                  <div
                    aria-label='thumbs down'
                    onClick={(event) => {
                      isAuthenticated
                        ? handleThumbDown(event)
                        : handleSignInModal(event);
                    }}
                  >
                    {thumbState.isDisliked ? (
                      <img
                        style={{
                          width: aboveLap ? '1.563rem' : '1.4rem',
                          height: aboveLap ? '1.563rem' : '1.4rem',
                          marginRight: '0.313rem',
                        }}
                        src={
                          isDarkMode
                            ? activeDislikeIconDarkMode
                            : activeDislikeIconLightMode
                        }
                        alt='active-thumb-down-icon'
                        className={classes.thumb}
                      />
                    ) : (
                      <img
                        style={{
                          width: aboveLap ? '1.563rem' : '1.4rem',
                          height: aboveLap ? '1.563rem' : '1.4rem',
                          marginRight: '0.313rem',
                        }}
                        src={
                          isDarkMode
                            ? inactiveThumbDownIconDark
                            : inactiveThumbDownIcon
                        }
                        alt='inactive-thumb-down-icon'
                        className={classes.thumb}
                      />
                    )}
                  </div>
                  <Typography
                    variant='subtitle4'
                    sx={{ fontSize: '0.875rem' }}
                    className={classes.newsCardReactions}
                  >
                    {thumbState.dislikeCount ? thumbState.dislikeCount : ''}
                  </Typography>
                </div> */}

                <div className={classes.thumb}>
                  <div
                    onClick={(event) => {
                      isAuthenticated
                        ? handleCommentsScroll(headline, url, event)
                        : handleSignInModal(event);
                    }}
                  >
                    <img
                      style={{
                        width: aboveLap ? '1.563rem' : '1.4rem',
                        height: aboveLap ? '1.563rem' : '1.4rem',
                        marginRight: '0.313rem',
                      }}
                      src={
                        isDarkMode ? commentIconDarkMode : commentIconLightMode
                      }
                      alt='active-thumb-down-icon'
                      className={classes.thumb}
                    />
                  </div>
                  <div aria-label='comment'></div>
                  <Typography
                    variant='subtitle4'
                    sx={{ fontSize: '0.875rem' }}
                    className={classes.newsCardReactions}
                  >
                    {commentsCount ? commentsCount : ''}
                  </Typography>
                </div>
                <div className={classes.thumb}>
                  <div
                    aria-label='share'
                    onClick={(event) => {
                      handleShare(event);
                    }}
                  >
                    <img
                      style={{
                        width: aboveLap ? '1.563rem' : '1.4rem',
                        height: aboveLap ? '1.563rem' : '1.4rem',
                      }}
                      src={isDarkMode ? shareIconDark : shareIcon}
                      alt='share-icon'
                    />
                  </div>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
        {isDetailPage && (
          <Box
            sx={{ px: '1rem' }}
            className={classes.detailedNewsCardDescription}
          >
            <Typography
              sx={{
                fontSize: aboveLap
                  ? '1.125rem'
                  : belowLap && aboveMobile
                  ? '1rem'
                  : '0.875rem',
              }}
              variant='body3'
              className={classes.detailedNewsCardParagraph}
            >
              <NewsDescription>
                {(story && parse(story)) || DESCRIPTION_FALLBACK}
              </NewsDescription>
            </Typography>
          </Box>
        )}

        {!!isDetailPage && (
          <Box
            sx={{
              pb: aboveLap || (belowLap && aboveMobile) ? '1.1rem' : 'none',
            }}
          >
            <Comments url={url} commentsRef={commentsRef} newsID={newsID} />
          </Box>
        )}
      </Box>
      <ReportModal
        showReportModal={showReportModal}
        closeModal={closeModal}
        newsID={newsID}
      />

      <Menu
        id='news-card-menu'
        anchorEl={openNewsCardpopover}
        keepMounted
        open={Boolean(openNewsCardpopover)}
        onClose={() => setOpenNewsCardpopover(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            minWidth: '12.5rem',
            backgroundColor: isDarkMode ? '#323232' : '',
            border: isDarkMode ? '' : '1px solid #CCCCCC',
            borderRadius: '0.5rem',
          },
          '& .MuiList-root': {
            py: '0.875rem',
            cursor: 'pointer',
          },
        }}
      >
        <ListItem
          name='bookmark'
          id='bookmark'
          aria-label='bookmark'
          onClick={(event) => {
            isAuthenticated ? handleBookmark(event) : handleSignInModal(event);
            setOpenNewsCardpopover(null);
          }}
        >
          {bookmarkState ? (
            <img
              src={activeBookmarkIcon}
              alt='active-bookmark-icon'
              className={classes.icons}
            />
          ) : (
            <img
              src={isDarkMode ? inactiveBookmarkIconDark : inactiveBookmarkIcon}
              alt='inactive-bookmark-icon'
              className={classes.icons}
            />
          )}
          {bookmarkState ? t('REMOVE_BOOKMARK') : t('BOOKMARK')}
        </ListItem>
        {trackingID !== userID && !anonymous && (
          <ListItem
            name='Follow Publisher'
            id='follow-publisher'
            aria-label='Follow Publisher'
            onClick={(event) => {
              isAuthenticated
                ? handleFollowUnfollowAuthor()
                : handleSignInModal(event);
              setOpenNewsCardpopover(null);
            }}
          >
            {isFollowedByUser ? (
              <img
                src={activeHeartIcon}
                alt='active-heart-icon'
                className={classes.icons}
              />
            ) : (
              <img
                src={isDarkMode ? inActiveHeartIconDark : inactiveHeartIcon}
                alt='inactive-heart-icon'
                className={classes.icons}
              />
            )}
            {trackingID !== userID &&
              (isFollowedByUser ? t('unfollow') : t('follow'))}
          </ListItem>
        )}
        {trackingID !== userID && can_flag !== false && (
          <ListItem
            name='flag'
            id='follow-publisher'
            aria-label='flag'
            onClick={(event) => {
              if (isAuthenticated) {
                setShowReportModal(true);
                setOpenNewsCardpopover(null);
              } else {
                handleSignInModal(event);
              }
              setOpenNewsCardpopover(null);
            }}
          >
            <img
              src={isDarkMode ? flagIconDark : flagIcon}
              alt='flag-icon'
              className={
                isDarkMode
                  ? classes.popOverFlagIconDark
                  : classes.popOverFlagIcon
              }
            />
            {t('REPORT')}
          </ListItem>
        )}
      </Menu>

      {sharePopupOpen && (
        <ShareDialog
          media={media}
          headline={headline}
          authorName={authorName}
          url={`${window.location.origin}/news${url}`}
          open={sharePopupOpen}
          handleClose={handleSharePopup}
        />
      )}
      <InfoModal
        message={t('Looks like this post has been removed.')}
        open={isPrivatePost}
        close={() => {
          setIsPrivatePost(false);
        }}
      >
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={() => {
            setIsPrivatePost(false);
          }}
          sx={{
            maxWidth: '18.75rem',
            width: '100%',
            textTransform: 'none',
          }}
        >
          {t('okay')}
        </Button>
      </InfoModal>
    </Box>
  );
}
